import React from 'react';
import {ROOT_PATH} from 'shared/constants/AppConst';

export const errorPagesConfigs = [
  {
    routes: [
      {
        path: `${ROOT_PATH}/error-pages/error-404`,
        component: React.lazy(() => import('./Error404')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/error-pages/error-500`,
        component: React.lazy(() => import('./Error500')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/error-pages/coming-soon`,
        component: React.lazy(() => import('./ComingSoon')),
      },
    ],
  },
  {
    routes: [
      {
        path: `${ROOT_PATH}/error-pages/maintenance`,
        component: React.lazy(() => import('./Maintenance')),
      },
    ],
  },
];
