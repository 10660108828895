import {makeStyles} from '@material-ui/core/styles';
import {ThemeMode} from '../../../../shared/constants/AppEnums';

const useStyles = makeStyles((theme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      paddingLeft:
        theme.direction === 'ltr' ? (props) => 20 + 40 * props.level : 12,
      paddingRight:
        theme.direction === 'rtl' ? (props) => 20 + 40 * props.level : 12,
      color: theme.palette.primary.textColor,
      fontWeight: 700,
      fontSize: '.9rem',
      cursor: 'pointer',
      textDecoration: 'none!important',

      [theme.breakpoints.up('xl')]: {
        // fontSize: 16,
        marginTop: 4,
        marginBottom: 4,
        paddingLeft:
          theme.direction === 'ltr' ? (props) => 20 + 40 * props.level : 12,
        paddingRight:
          theme.direction === 'rtl' ? (props) => 20 + 40 * props.level : 12,
      },
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
    },
  };
});

export default useStyles;
