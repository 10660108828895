import {AUTH_ROLE, authRole} from '../shared/constants/AppConst';
import {ROOT_PATH} from '../shared/constants/AppConst';

const routesConfig = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'menu.home',
    type: 'item',
    icon: 'apps',
    url: `${ROOT_PATH}/dashboard`,
    // auth: authRole.pilot,
  },
  {
    id: 'drone',
    title: 'drone',
    messageId: 'drone',
    type: 'item',
    icon: 'flight',
    url: `${ROOT_PATH}/list-drone`,
    auth: authRole.companyOwner,
  },
  {
    id: 'drone-pilot',
    title: 'drone-pilot',
    messageId: 'dronePilot',
    type: 'item',
    icon: 'flight',
    url: `${ROOT_PATH}/list-drone-pilot`,
    auth: authRole.pilot,
    notDisplayAuth: authRole.companyOwner,
  },
  {
    id: 'pilot',
    title: 'pilot',
    messageId: 'pilot',
    type: 'item',
    icon: 'accessibility',
    url: `${ROOT_PATH}/list-pilot`,
    auth: authRole.companyOwner,
  },
  {
    id: 'output-log',
    title: 'output-log',
    messageId: 'outputLog',
    type: 'item',
    icon: 'assignment',
    url: `${ROOT_PATH}/output-log`,
    auth: authRole.companyOwner,
  },
  {
    id: 'journey-log',
    title: 'journey-log',
    messageId: 'drone.flightLog',
    type: 'item',
    icon: 'map',
    url: `${ROOT_PATH}/flight-log`,
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
  },
  {
    id: 'checking-log',
    title: 'checking-log',
    messageId: 'drone.checkingLog',
    type: 'item',
    icon: 'assignmentTurnedIn',
    url: `${ROOT_PATH}/checking-log`,
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
  },
  {
    id: 'maintenance-log',
    title: 'maintenance-log',
    messageId: 'drone.maintenanceLog',
    type: 'item',
    icon: 'engineering',
    url: `${ROOT_PATH}/maintenance-log`,
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
  },
  {
    id: 'notifications',
    title: 'notifications',
    messageId: 'notifications',
    type: 'item',
    icon: 'notifications',
    url: `${ROOT_PATH}/notifications`,
    auth: authRole.companyOwner,
  },
  {
    id: 'company',
    title: 'company',
    messageId: 'company',
    type: 'item',
    url: `${ROOT_PATH}/company`,
    icon: 'business',
    auth: authRole.admin,
  },
  {
    id: 'user',
    title: 'user',
    messageId: 'user',
    type: 'item',
    icon: 'person',
    url: `${ROOT_PATH}/user`,
    auth: authRole.admin,
  },
  {
    id: 'export-excel-pdf',
    title: 'exportLog.pdf',
    messageId: 'exportLog.pdf',
    type: 'item',
    icon: 'picture_as_pdf',
    url: `${ROOT_PATH}/export-pdf-log`,
    auth: authRole.pilot,
  },
  {
    id: 'export-excel-log',
    title: 'exportLog.excel',
    messageId: 'exportLog.excel',
    type: 'item',
    icon: 'file_copy',
    url: `${ROOT_PATH}/export-excel-log`,
    auth: authRole.pilot,
  },
  {
    id: 'setting',
    title: 'setting',
    messageId: 'menu.setting',
    auth: authRole.admin,
    type: 'collapse',
    icon: 'settings',
    children: [
      {
        id: 'airframe',
        title: 'airframe',
        messageId: 'airframe',
        type: 'item',
        url: `${ROOT_PATH}/management/airframe`,
      },
      {
        id: 'drone-types',
        title: 'drone-types',
        messageId: 'droneTypes',
        type: 'item',
        url: `${ROOT_PATH}/management/drone-types`,
      },
      {
        id: 'manufacturer',
        title: 'manufacturer',
        messageId: 'manufacturer',
        type: 'item',
        url: `${ROOT_PATH}/management/manufacturer`,
      },
      {
        id: 'flight-method',
        title: 'flight-method',
        messageId: 'flightMethod',
        type: 'item',
        url: `${ROOT_PATH}/management/flight-method`,
      },
      {
        id: 'flight-purpose',
        title: 'flight-purpose',
        messageId: 'flightPurpose',
        type: 'item',
        url: `${ROOT_PATH}/management/flight-purpose`,
      },
      {
        id: 'no-fly-zone-method',
        title: 'no-fly-zone-method',
        messageId: 'noFlyZoneMethod',
        type: 'item',
        url: `${ROOT_PATH}/management/no-fly-zone-method`,
      },
      {
        id: 'global-property',
        title: 'global-property',
        messageId: 'globalProperty',
        type: 'item',
        url: `${ROOT_PATH}/management/global-property`,
      },
    ],
  },
];
export default routesConfig;
