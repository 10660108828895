import React from 'react';
import {ROOT_PATH, authRole} from '../../shared/constants/AppConst';

export const UserConfig = [
  {
    auth: authRole.companyOwner,
    routes: [
      {
        path: `${ROOT_PATH}/user`,
        component: React.lazy(() => import('./Index.jsx')),
      },
    ],
  },
];
