import React, {useContext} from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import {ContentView, ThemeSetting} from '../../../index';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import {useMediaQuery, useTheme} from '@material-ui/core';

const StandardLayout = () => {
  const {footer, themeStyle, layoutType, footerType} = useContext(AppContext);
  const classes = useStyles({footer, themeStyle});

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppSidebar />

      <Box className={`${classes.mainContent} main-content`}>
        <Box
          className={`${
            isMd ? classes.mainContainerFull : classes.mainContainer
          }`}>
          <AppHeader />
          <ContentView />
          <AppFixedFooter />
        </Box>
      </Box>
      <ThemeSetting />
    </Box>
  );
};

export default StandardLayout;
