import React  from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  logoRoot: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  logo: {
    height: 36,
    marginRight: 10,
  },
}));

const AppLogo = () => {
  const classes = useStyles();

  const { user } = useSelector(({ auth }) => auth);
  return (
    <Box className={classes.logoRoot}>
      <img className={classes.logo} src={user.logoCompany} alt='company-logo' />
    </Box>
  );
};

export default AppLogo;


// import React, {useContext} from 'react';
// import {Box} from '@material-ui/core';
// import {makeStyles} from '@material-ui/core/styles';
// import AppContext from '../../../@crema/utility/AppContext';
// import {ThemeMode} from '../../constants/AppEnums';
// import Hidden from '@material-ui/core/Hidden';

// const AppLogo = () => {
//   const {themeMode} = useContext(AppContext);
//   const useStyles = makeStyles(() => ({
//     logoRoot: {
//       display: 'flex',
//       flexDirection: 'row',
//       cursor: 'pointer',
//       alignItems: 'center',
//     },
//     logo: {
//       height: 36,
//       marginRight: 10,
//     },
//   }));
//   const classes = useStyles();
//   return (
//     <Box className={classes.logoRoot}>
//       <Hidden smUp>
//         <img
//           className={classes.logo}
//           src={
//             themeMode === ThemeMode.DARK
//               ? '/assets/images/avilog-uav.png'
//               : '/assets/images/avilog-uav.png'
//           }
//           alt='crema-logo'
//         />
//       </Hidden>
//       <Hidden xsDown>
//         <img
//           className={classes.logo}
//           src={
//             themeMode === ThemeMode.DARK
//               ? '/assets/images/avilog-uav.png'
//               : '/assets/images/avilog-uav.png'
//           }
//           alt='crema-logo'
//         />
//       </Hidden>
//     </Box>
//   );
// };

// export default AppLogo;
