export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
  companyOwner: ['company-owner', 'admin'],
  pilot: ['pilot'],
};

export const AUTH_ROLE = {
  ADMIN: 'admin',
  COMPANY_OWNER: 'company-owner',
  PILOT: 'pilot',
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const API_ENPOINT =
  process.env.REACT_APP_BACKEND_URL || 'https://localhost:8000/api';
export const ROOT_PATH = process.env.REACT_APP_ROOT_PATH || '';
export const initialUrl = ROOT_PATH + '/dashboard'; // this url will open after login
