import React, {useContext, useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {ROOT_PATH, initialUrl} from '../../shared/constants/AppConst';
import {SIGNOUT_AUTH_SUCCESS} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

const AuthRoutes = ({children}) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes} = useContext(AppContext);

  const [loading, user] = useAuthToken();
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  let isPermitted = checkPermission(currentRoute.auth, user?.role);

  useEffect(() => {
    if (!loading) {
      if (!user || !isPermitted) {
        history.push(`${ROOT_PATH}/signin`); // allowed route
        return;
      }

      if (user && !isPermitted) {
        history.push(`${ROOT_PATH}/error-pages/error-404`); // Not found
        return;
      }

      if (
        pathname === `${ROOT_PATH}/signin` ||
        pathname === `${ROOT_PATH}/forget-password`
      ) {
        history.push(initialUrl);
      }
    }
  }, [user, loading, isPermitted, history]);

  useMemo(() => {
    jwtAxios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (
          err.response &&
          (err.response.status === '401' || err.response.status === 401)
        ) {
          history.push(`${ROOT_PATH}/signin`);
          dispatch({type: SIGNOUT_AUTH_SUCCESS});
        }
        throw err;
      },
    );
    return null;
  }, []);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

// import React, {useContext, useEffect, useMemo} from 'react';
// import {useHistory, useLocation} from 'react-router-dom';
// import {useDispatch, useSelector} from 'react-redux';
// import {matchRoutes} from 'react-router-config';
// import qs from 'qs';
// import AppContext from './AppContext';
// import {useAuthToken} from './AppHooks';
// import {Loader} from '../index';
// import PropTypes from 'prop-types';
// import {checkPermission} from './Utils';
// import {ROOT_PATH, initialUrl} from '../../shared/constants/AppConst';
// import {setInitialPath} from '../../redux/actions';
// import {SIGNOUT_AUTH_SUCCESS} from 'shared/constants/ActionTypes';
// import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

// const AuthRoutes = ({children}) => {
//   const {pathname, search} = useLocation();
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const {routes, changeNavStyle, updateThemeStyle, updateThemeMode, setRTL} =
//     useContext(AppContext);

//   const [loading, user] = useAuthToken();
//   const initialPath = useSelector(({settings}) => settings.initialPath);
//   const currentRoute = matchRoutes(routes, pathname)[0].route;

//   let isPermitted = checkPermission(currentRoute.auth, user?.role);
//   // console.log(currentRoute.auth)

//   useEffect(() => {
//     function setInitPath() {
//       if (
//         initialPath === '/' &&
//         [
//           `${ROOT_PATH}/signin`,
//           `${ROOT_PATH}/signup`,
//           `${ROOT_PATH}/confirm-signup`,
//           `${ROOT_PATH}/reset-password`,
//           `${ROOT_PATH}/error-pages/error-404`,
//           `${ROOT_PATH}/forget-password`,
//         ].indexOf(pathname) === -1
//       ) {
//         if (isPermitted) {
//           dispatch(setInitialPath(pathname));
//         } else {
//           dispatch(setInitialPath(undefined));
//         }
//       }
//     }

//     setInitPath();
//   }, [dispatch, isPermitted, initialPath, pathname]);

//   useEffect(() => {
//     function handleQueryParams() {
//       const query = qs.parse(search.split('?')[1]);
//       if (query.layout) {
//         changeNavStyle(query.layout);
//       }
//       if (query.mode) {
//         updateThemeMode(query.mode);
//       }
//       if (query.rtl) {
//         setRTL(true);
//       }
//       if (query.style) {
//         updateThemeStyle(query.style);
//       }
//     }

//     if (search) {
//       handleQueryParams();
//     }
//   }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

//   useEffect(() => {
//     if (!loading) {
//       if (!user && !isPermitted) {
//         history.push(`${ROOT_PATH}/signin`); // allowed route
//         return;
//       }

//       if (user && !isPermitted) {
//         history.push(`${ROOT_PATH}/error-pages/error-404`); // Not found
//         return;
//       }

//       if (user && isPermitted) {
//         if (
//           pathname === ROOT_PATH ||
//           pathname === `${ROOT_PATH}/signin` ||
//           pathname === `${ROOT_PATH}/signup`
//         ) {
//           history.push(initialUrl);
//         } else if (
//           initialPath &&
//           initialUrl !== initialPath &&
//           [
//             `${ROOT_PATH}/`,
//             `${ROOT_PATH}/signin`,
//             `${ROOT_PATH}/signup`,
//           ].includes(initialPath)
//         ) {
//           history.push(initialPath);
//         }
//       }
//     }
//   }, [user, loading, initialPath, isPermitted, pathname, history]);

//   useMemo(() => {
//     jwtAxios.interceptors.response.use(
//       (res) => res,
//       (err) => {
//         if (
//           err.response &&
//           (err.response.status === '401' || err.response.status === 401)
//         ) {
//           history.push(`${ROOT_PATH}/signin`);
//           dispatch({type: SIGNOUT_AUTH_SUCCESS});
//         }
//         throw err;
//       },
//     );
//     return null;
//   }, []);

//   return loading ? <Loader /> : <>{children}</>;
// };

// export default AuthRoutes;

// AuthRoutes.propTypes = {
//   children: PropTypes.node.isRequired,
// };
