export const STATUS_SUCCESS = 'Success';
export const ACCEPT_FORMAT_IMAGE = '.jpeg, .jpg, .png, .gif, .webp, .avif';
export const ITEM_ALL_CERTIFICATE = 'すべて';
export const OutputLogType = {
  JOURNEY_LOG: 1,
  PILOT_LOG: 2,
  CHECKING_LOG: 3,
  MAINTENANCE_LOG: 4,
};
export const LIST_GENDER = [
  {name: 'gender.male', value: 'M'},
  {name: 'gender.female', value: 'F'},
];

export const LIST_OUTPUT_LOG_TYPE = [
  {
    value: OutputLogType.JOURNEY_LOG,
    name: 'drone.journeyLog',
    fileName: '無人航空機航空日誌',
  },
  {
    value: OutputLogType.PILOT_LOG,
    name: 'drone.pilotLog',
    fileName: '無人航空機操縦者飛行日誌',
  },
  {
    value: OutputLogType.CHECKING_LOG,
    name: 'outputLog.checkingLog',
    fileName: '日常点検表',
  },
  {
    value: OutputLogType.MAINTENANCE_LOG,
    name: 'drone.maintenanceLog',
    fileName: '整備記',
  },
];

export const LIST_PILOT_LOG_TYPE = [
  {
    value: OutputLogType.JOURNEY_LOG,
    name: 'drone.flightLog',
    fileName: '無人航空機航空日誌',
  },
  {
    value: OutputLogType.CHECKING_LOG,
    name: 'outputLog.checkingLog',
    fileName: '日常点検表',
  },
  {
    value: OutputLogType.MAINTENANCE_LOG,
    name: 'drone.maintenanceLog',
    fileName: '整備記',
  },
];

export const LIST_TYPE_GLOBAL_PROPERTY = [
  {name: 'common.url', value: 1},
  {name: 'common.userSetting', value: 2},
  {name: 'common.paymentUrl', value: 3},
];

export const LIST_CLASSIFICATION_OF_MACHINE_AUTHENTICATION = [
  {name: '1種', value: '1種'},
  {name: '2種', value: '2種'},
];

export const LIST_CERTIFICATE_CATEGORY = [
  {id: 'マルチ', name: 'マルチ'},
  {id: '飛行機', name: '飛行機'},
  {id: 'ヘリコプター', name: 'ヘリコプター'},
  {id: 'VTOL', name: 'VTOL'},
];

export const LIST_CERTIFICATE_LIMITATION = [
  {id: '基本', name: '基本'},
  {id: '25kg', name: '25kg'},
  {id: '昼間', name: '昼間'},
  {id: '目視内', name: '目視内'},
  {id: 'なし', name: 'なし'},
];

export const LIST_CERTIFICATE_CLASS = [
  {name: '民間資格', value: '民間資格'},
  {name: '一等無人航空機操縦者', value: '一等無人航空機操縦者'},
  {name: '二等無人航空機操縦者', value: '二等無人航空機操縦者'},
];
