import React from 'react';
import {AUTH_ROLE, ROOT_PATH, authRole} from '../../shared/constants/AppConst';

export const OutputLogConfig = [
  {
    auth: authRole.companyOwner,
    routes: [
      {
        path: `${ROOT_PATH}/output-log`,
        component: React.lazy(() => import('./Index.jsx')),
      },
    ],
  },
  {
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
    routes: [
      {
        path: `${ROOT_PATH}/flight-log`,
        component: React.lazy(() => import('./FlightLogList.jsx')),
      },
    ],
  },
  {
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
    routes: [
      {
        path: `${ROOT_PATH}/checking-log`,
        component: React.lazy(() => import('./CheckingLogList.jsx')),
      },
    ],
  },
  {
    auth: [...authRole.pilot, AUTH_ROLE.COMPANY_OWNER],
    routes: [
      {
        path: `${ROOT_PATH}/maintenance-log`,
        component: React.lazy(() => import('./MaintenanceLogList.jsx')),
      },
    ],
  },
];
