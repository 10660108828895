import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../shared/constants/ActionTypes';
import Auth0Config from '../../@crema/services/auth/auth0/auth0Config';
import { AuthType } from '../../shared/constants/AppEnums';
import { setJWTToken } from './JWTAuth';
import { ROOT_PATH, defaultUser } from '../../shared/constants/AppConst';

export const onSignInAuth0User = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const auth0 = await Auth0Config();
      await auth0.loginWithPopup({});
      auth0
        .getUser()
        .then((user) => {
          dispatch({ type: FETCH_SUCCESS });
          dispatch(setJWTToken(user.sub));
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: getUserObject(user),
          });
        })
        .catch((error) => {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

const getUserObject = (user) => {
  return {
    authType: AuthType.AUTH0,
    role: defaultUser.role,
    uid: user.sub,
    displayName: user.name,
    email: user.email,
    photoURL: user.picture,
    token: user.sub,
  };
};

export const onSignOutAuth0User = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const auth0 = await Auth0Config();
      auth0.logout();
      dispatch({ type: UPDATE_AUTH_USER, payload: null });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

export const getDataUser = async (user) => {
  let avatarImage = null;
  let logoCompany = `${ROOT_PATH}/assets/images/avilog-uav.png`;
  let backgroundCompany = null;

  try {
    // if (user?.person?.avatarImage) {
    //   avatarImage = (await getUrlImgPerson(user?.person?.avatarImage)).data?.data;
    // }

    // if (user?.person?.company?.logo) {
    //   logoCompany = (await getUrlImgPerson(user?.person?.company?.logo)).data?.data;
    // }

    // if (user?.person?.company?.backgroundImage) {
    //   backgroundCompany = (await getUrlImgPerson(user?.person?.company?.backgroundImage)).data?.data;
    // }


    if (user?.person?.avatarImage) {
      avatarImage = user?.person?.avatarImage
    }

    if (user?.person?.company?.logo) {
      logoCompany = user?.person?.company?.logo;
    }

    if (user?.person?.company?.backgroundImage) {
      backgroundCompany = user?.person?.company?.backgroundImage
    }
  } catch (error) { }

  return {
    ...user,
    logoCompany: logoCompany,
    backgroundCompany: backgroundCompany,
    photoURL: avatarImage,
  }
}